import { useGtm } from '@gtm-support/vue-gtm';
import { ValidateFieldsError } from "async-validator";

export function scrollTop (toScrollSelector: string = '', scrollIfAbove = true) {
  if(toScrollSelector) {
    const destination = document.querySelector(toScrollSelector) as HTMLElement;
    if(destination) {
      const navBar = document.querySelector("#navbar") as HTMLElement;
      const scrollOffset = destination.offsetTop - (navBar ? navBar.offsetHeight : 0);
      
      if(scrollOffset > window.scrollY && !scrollIfAbove) {
        return;
      }

      window.scrollTo(0, scrollOffset);
    }
    
  } else {
    window.scrollTo(0, 0);
  }
  
  return;
}

export function CmsBaseUrl () {
  return process.env.VUE_APP_APOLLO_ENGINE_SERVICE
}

export function triggerGtmEvent (event: Object) {
  const gtm = useGtm();
  gtm?.trackEvent(event);
}

export function hasProp (obj: any = null, prop: string) {
  if (obj && prop) {
    const hasProp = Object.prototype.hasOwnProperty.call(obj, prop);

    if (hasProp) {
      return (obj[prop] !== "") ? true : false;
    }
  }
  return false;
}

import { useHead, useSeoMeta } from "@unhead/vue"
import { seo } from '@/utils/graphql/seo'
export function setMetaData (path: string, client: any) {
  if(path == "home" && window.location.pathname != "/") {
    return false
  }
  const defaultTitle = "Lubanzi Inala"
  const defaultDescription = ""
  new Promise (() => {
    client.query({
      query: seo,
      variables: {
        url: path
      }
    }).then((data: any) => {
      const fullPath = window.location.origin + (path == "home" ? "" : path)
      if(data.data.sEO && data.data.sEO.length > 0) {
        const meta = data.data.sEO[0].seoMeta
        const ogImagePath = getOgImage(meta)
        const twitterImagePath = getTwitterImage(meta)

        useHead({
          title: meta.pageTitle,
          meta: [
            { name: "description", content: meta.metaDescription ? meta.metaDescription : defaultDescription },
            { name: "keywords", content: meta.metaKeywords ? meta.metaKeywords : undefined, key: "keywords-tag" },
            { name: "robots", content: meta.metaRobots ? meta.metaRobots : undefined, key: "robots-tag" }
          ],
          link: [{ rel: "canonical", href: meta.canonical ? meta.canonical : undefined, key: "cannonical-tag" }],
          script: [{ type: "application/ld+json", innerHTML: meta.googleSchema ? meta.googleSchema : "", key: "schema-tag" }]
        })

        useSeoMeta({
          ogType: "website",
          ogDescription: meta.metaDescription ? meta.metaDescription : defaultDescription,
          ogTitle: meta.pageTitle ? meta.pageTitle : defaultTitle,
          ogImage: CmsBaseUrl() + ogImagePath,
          ogUrl: fullPath,
          ogSiteName: "Lubanzi Inala",
          ogLocale: "en_GB",
          twitterSite: "Lubanzi Inala",
          twitterTitle: meta.pageTitle ? meta.pageTitle : defaultTitle,
          twitterDescription: meta.metaDescription ? meta.metaDescription : defaultDescription,
          twitterImage: CmsBaseUrl() + twitterImagePath,
        })
      }
      else {
        setDefaults(fullPath)
      }
    })
  })

  function setDefaults(fullPath: string) {
    useHead({
      title: "",
      meta: [
        { property: "og:locale", content: "en_GB" },
        { name: "keywords", content: undefined, key: "keywords-tag" },
        { name: "robots", content: undefined, key: "robots-tag" }
      ],
      link: [{ rel: "canonical", href: "", key: "cannonical-tag" }],
      script: [{ type: "application/ld+json", innerHTML: "{}", key: "schema-tag" }]
    })

    useSeoMeta({
      ogType: "website",
      ogDescription: defaultDescription,
      ogTitle: defaultTitle,
      ogImage: CmsBaseUrl() + "/media/social-media-sharing/default_opengraph.jpg",
      ogUrl: fullPath,
      ogSiteName: "Lubanzi Inala",
      ogLocale: "en_GB",
      twitterSite: "Lubanzi Inala",
      twitterTitle: defaultTitle,
      twitterDescription: defaultDescription,
      twitterImage: CmsBaseUrl() + "/media/social-media-sharing/default_opengraph.jpg",
    })
  }

  function getOgImage(meta: any) {
    const image = meta.openGraphImage
    const fallback = meta.defaultSocialImage
    if(image && image.urls.length > 0) {
      return image.urls[0]
    }
    else if(fallback && fallback.urls.length > 0) {
      return fallback.urls[0]
    }
    return "/media/social-media-sharing/default_opengraph.jpg"
  }

  function getTwitterImage(meta: any) {
    const image = meta.twitterImage
    const fallback = meta.defaultSocialImage
    if(image && image.urls.length > 0) {
      return image.urls[0]
    }
    else if(fallback && fallback.urls.length > 0) {
      return fallback.urls[0]
    }
    return "/media/social-media-sharing/default_twitter.jpg"
  }
}

export function handleInvalidForm(fields: ValidateFieldsError) {
  if (fields) {
    const field = Object.values(fields)[0];
    if (field[0]?.field) {
      document.getElementsByName(field[0].field)[0]?.focus();
    }
  }
}