import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "AboutUs" */ '../views/AboutUs.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs.vue')
  },
  {
    path: '/our-partners',
    name: 'Partners',
    component: () => import(/* webpackChunkName: "Partners" */ '../views/Partners.vue')
  },
  {
    path: '/what-we-do',
    name: 'WhatWeDo',
    component: () => import(/* webpackChunkName: "WhatWeDo" */ '../views/WhatWeDo.vue')
  },
  // {
  //   path: '/paia',
  //   name: 'PAIA',
  //   component: () => import(/* webpackChunkName: "PAIA" */ '../views/PAIA.vue')
  // },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'TnC',
    component: () => import(/* webpackChunkName: "TnC" */ '../views/TnC.vue')
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFoundView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const hamburgerMenu: HTMLElement | null = document.querySelector(".navbar-toggler:not([aria-expanded='false'])")
    if (hamburgerMenu && hamburgerMenu.offsetWidth > 0 && hamburgerMenu.offsetHeight > 0) {
      hamburgerMenu.click();
    }
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition
    // For anchors
    } else if (to.hash) {
      return { el: to.hash }
    // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    } else if (from.path === to.path) {
      return {}
    }

    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split('?')[0] == from.fullPath.split('?')[0]) return;
    }

    return { top: 0, behavior: 'smooth' }; 
  }
})

export default router