<template>
    <nav class="navbar navbar-expand-lg bg-white sticky-top">
        <div class="container-lg py-1">
            <router-link class="navbar-brand logo-style" :to="{ name: 'Home' }" @click="homeScroll()">
                <img src="/lubanzi-inala_logo.svg" class="w-100 img-fluid" alt="Lubanzi Inala" />
            </router-link>
            <button class="navbar-toggler" @click="toggleClick()" type="button" data-bs-toggle="collapse" data-bs-target="#NavDropdown" aria-controls="NavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-lg-end" id="NavDropdown">
                <ul class="navbar-nav text-uppercase">
                    <li class="nav-item mx-0 mx-lg-2">
                        <router-link class="nav-link text-primary px-3" :to="{ name: 'AboutUs' }">About Us</router-link>
                    </li>
                    <li class="nav-item mx-0 mx-lg-2">
                        <router-link class="nav-link text-primary px-3" :to="{ name: 'WhatWeDo' }">What We Do</router-link>
                    </li>
                    <li class="nav-item mx-0 mx-lg-2">
                        <router-link class="nav-link text-primary px-3" :to="{ name: 'Partners' }">Partners</router-link>
                    </li>
                    <li class="nav-item ms-3 ms-lg-2 mt-2 mt-lg-0">
                        <router-link class="btn btn-primary px-4" :to="{ name: 'ContactUs' }">Contact Us</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
    import { scrollTop } from "@/utils/functions"
    function homeScroll () {
        if(window.scrollY == 0) {
            return
        }
        
        setTimeout(() => {
            scrollTop('')
        }, 50)
    }
    function toggleClick () {
        const menu: HTMLElement | null = document.querySelector(".navbar-toggler:not([aria-expanded='false'])")
        if (menu) {
            document.querySelector("main")!.addEventListener("click", collapseMenu);
        }
        else {
            document.querySelector("main")!.removeEventListener("click", collapseMenu);
        }
    }
    function collapseMenu () {
        const hamburgerMenu: HTMLElement | null = document.querySelector(".navbar-toggler:not([aria-expanded='false'])")
        if (hamburgerMenu && hamburgerMenu.offsetWidth > 0 && hamburgerMenu.offsetHeight > 0) {
            hamburgerMenu.click();
        }
        document.querySelector("main")!.removeEventListener("click", collapseMenu);
    }
    
</script>

<style lang="scss" scoped>
    .navbar-nav {
        .nav-link {
            font-weight: 700;
        }
        .btn {
            font-weight: 600;
        }
    }
    .navbar-brand {
        img {
            max-height: 41px;
        }
    }
    @include media-breakpoint-down(sm) {
        .navbar-brand {
            display: inline-block;
            max-width: calc(100% - 85px);
        }
    }
</style>