<template>
    <footer class="text-white bg-tertiary py-4">
        <div class="container-lg pb-5">
            <div class="row">
                <div class="col col-md-3 col-lg-2 order-0">
                    <h2 class="h6 mt-5 mb-3 my-md-4">Quick Links</h2>
                    <router-link class="d-block py-1" :to="{ name: 'AboutUs' }">About Us</router-link>
                    <router-link class="d-block py-1" :to="{ name: 'WhatWeDo' }">What We Do</router-link>
                    <router-link class="d-block py-1" :to="{ name: 'Partners' }">Our Partners</router-link>
                    <router-link class="d-block py-1" :to="{ name: 'ContactUs' }">Contact Us</router-link>
                </div>
                <div class="col col-sm-6 col-md-3 col-lg-3 col-xl-2 order-md-1">
                    <h2 class="h6 mt-5 mb-3 my-md-4">Corporate</h2>
                    <router-link class="d-block py-1" :to="{ name: 'TnC' }">Terms &amp; Conditions</router-link>
                    <router-link class="d-block py-1" :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
                </div>
                <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 mx-0 mx-md-auto mx-lg-0 order-md-0">
                    <h2 class="h6 mt-5 mb-3 my-md-4">Address</h2>
                    <p style="line-height: 26px;" class="mt-1 pt-1 mb-3">
                        Office 3 | Rydallviews |
                        5A Rydall Vale Office Park |
                        38 Douglas Saunders Drive |
                        La Lucia Ridge | Durban
                    </p>
                    <p style="line-height: 26px;">
                        Unit 2 | 25 Merlot Close |
                        JT Ross Park | Plumbago Business Park |
                        Kempton Park | Gauteng
                    </p>
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-lg-3 text-center ms-lg-auto order-1">
                    <img src="/lubanzi-inala_logo-footer.png" class="img-fluid mt-5 mt-md-4 mb-2" alt="Lubanzi Inala" />
                    <p class="lh-1 my-2"><a class="text-white" href="mailto:info@lubanzi-inala.co.za">info@lubanzi-inala.co.za</a></p>
                    &copy; {{ new Date().getFullYear() }} Lubanzi Inala
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
    footer {
        a {
            color: #ffffff;
            text-decoration: none;
        }
        .social-media {
            .col {
                font-size: 1.8rem;
                flex: 0 0 auto;
                margin-right: 4px;
            }
        }
    }
</style>